import * as React from "react"
import {connect} from "react-redux"
import {RootState} from "../../store/reducers/reducers"
import {getUserDisplayName, getUserEmail} from "../../store/reducers/selectors"
import WaterManagerDrawerHeader from "./WaterManagerDrawerHeader";


interface StateProps {
	email: String
	name: String
}

const WaterManagerDrawerHeaderConnected: React.FC<StateProps> = (props) => <WaterManagerDrawerHeader {...props}/>


const mapStateToProps = (state: RootState): StateProps => ({
	email: getUserEmail(state),
	name: getUserDisplayName(state)
})

export default connect<StateProps>(mapStateToProps)(WaterManagerDrawerHeaderConnected)
