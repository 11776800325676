import * as React from "react"
import {VictoryAxis, VictoryLine, VictoryTheme, VictoryZoomContainer} from "victory"
import {GraphValue} from "../../types/types"
import {ViewPortSize} from "../../types/ViewPortSize"
import {TimeSeriesDomain} from "./victoryTypeExtensions"
import {GraphData} from "../../types/GraphData";
import VictoryChart from './IEFriendlyVictoryChart';
import moment from 'moment';
import 'moment/locale/de';


const DATA_OVERLAP_POINTS = 5

interface Props {
	data: GraphData
	viewPortSize: ViewPortSize
	height: number
}

interface State {
	endDate: Date
}

class Graph extends React.Component <Props, State> {

	public readonly state: State = {
		endDate: new Date()
	}

	// noinspection JSMethodCanBeStatic
	private get chartWidth(): number {
		return window.innerWidth
	}

	private get viewPortSizeDays(): number {
		switch (this.props.viewPortSize) {
			case ViewPortSize.WEEK:
				return 7
			case ViewPortSize.MONTH:
				return 30
			case ViewPortSize.DAY:
			default:
				return 1
		}
	}

	private get startDate(): Date {
		return new Date(this.endDate.getTime() - this.viewPortSizeDays * 24 * 60 * 60 * 1000)
	}

	private get endDate(): Date {
		return this.state.endDate
	}

	private get minimumDate(): Date {
		const minimumDataDate = this.props.data.minDate
		const startDate = this.startDate
		return startDate < minimumDataDate ? startDate : minimumDataDate
	}

	private get yDomain(): [number, number] {
		const min = this.props.data.minValue
		const max = this.props.data.maxValue

		if (min === max) {
			return [min, min + 1] //otherwise we receive a warnign
		} else {
			return [min, max]
		}
	}

	private onZoomDomainChange = (domain: TimeSeriesDomain) => {
		this.setState({endDate: domain.x[1]})
	}

	private get data(): GraphValue[] {
		const allData = this.props.data.values
		const startDate = this.startDate.getTime()
		const endDate = this.endDate.getTime()

		const startIndex = Math.max(0, allData.findIndex(it => endDate >= it.date.getTime()) - DATA_OVERLAP_POINTS)
		const foundEndIndex = allData.findIndex(it => startDate > it.date.getTime())
		const endIndex = foundEndIndex < 0 ? allData.length - 1 : Math.min(allData.length - 1, foundEndIndex + DATA_OVERLAP_POINTS)

		return allData.slice(startIndex, endIndex)
	}

	public render() {
		const yDomain = this.yDomain
		return <VictoryChart theme={VictoryTheme.material}
							 scale={{x: "time", y: "linear"}}
							 width={this.chartWidth}
							 height={this.props.height}
							 domain={{x: [this.minimumDate, new Date()], y: yDomain}}
							 containerComponent={<VictoryZoomContainer zoomDimension="x" zoomDomain={{x: [this.startDate, this.endDate], y: yDomain}} onZoomDomainChange={this.onZoomDomainChange}/>}
		>

			<VictoryAxis
				tickCount={this.getTickCount()}
				tickFormat={this.getTickText}
			/>
			<VictoryAxis dependentAxis
						 tickCount={10}
						 fixLabelOverlap={true}/>

			<VictoryLine
				style={{
					data: {stroke: "#c43a31"},
					parent: {border: "1px solid #ccc"}
				}}
				interpolation={"monotoneX"}
				data={this.data}
				x={"date"}
				y={"value"}
				padding={{bottom: 0, top: 0}}
			/>

		</VictoryChart>
	}


	public componentDidMount(): void {
		window.addEventListener('resize', this.resizeHandler)
	}

	public componentWillUnmount(): void {
		window.removeEventListener('resize', this.resizeHandler)
	}

	private resizeHandler = () => {
		this.forceUpdate()
	}

	private getTickText = (date: Date): string => {
		const momentDate = moment(date).locale('de');

		if (momentDate.dayOfYear() === 1 && momentDate.hour() === 0) {
			return momentDate.format("DD. MMM YYYY")

		} else if (momentDate.hour() === 0) {
			return momentDate.format("DD. MMM")

		} else {
			return momentDate.format("HH:mm")
		}
	}

	private getTickCount(): number {
		return Math.min(24, Math.round(this.chartWidth / 110))
	}
}

export default Graph
