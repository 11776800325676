import {Dispatch} from "redux"
import {createAction} from "typesafe-actions"
import * as BackendApi from "../../api/BackendApi"
import {UserDto} from "../../api/dto/UserDto"
import {getAuth, shouldPersistCredentials} from "../../utils/utils";
import {getStateType} from "../store";
import {push} from "connected-react-router";
import {saveAuthState} from "../localStorage";


export const checkLogin = () => (dispatch: Dispatch, getState: getStateType) => {
	const state = getState()
	const auth = getAuth(state)

	if (!auth.username || !auth.password) {
		dispatch(push("/login"))
	} else {
		BackendApi.getUserData(auth)
			.then(user => {
				handleLogin(dispatch, auth.username, auth.password, shouldPersistCredentials(state), user)
			})
			.catch(() => {
				dispatch(logout())
			})
	}
}

export const loginUser = (email: string, password: string, persistCredentials: boolean) => (dispatch: Dispatch) => {
	return BackendApi.getUserData({username: email, password})
		.then(user => {
			handleLogin(dispatch, email, password, persistCredentials, user)
		})
}

const handleLogin = (dispatch: Dispatch, email: string, password: string, persistCredentials: boolean, user: UserDto) => {
	dispatch(receiveLoginSuccess(email, password, persistCredentials))
	dispatch(receiveUserData(user))
	dispatch(push("/"))
	saveAuthState({username: email, password}, persistCredentials)
}

export const receiveLoginSuccess = createAction("users/receiveLoginSuccess", resolve => (email: string, password: string, persistCredentials: boolean) =>
	resolve({email, password, persistCredentials}))

export const receiveUserData = createAction("users/receiveUserData", resolve => (user: UserDto) => resolve({user}))

export const onLogout = () => (dispatch: Dispatch) => {
	dispatch(logout())
	dispatch(push("/"))
}

export const logout = createAction("users/logout")


export const changePassword = (oldPassword: string, newPassword: string) => (dispatch: Dispatch, getState: getStateType) => {
	return BackendApi.changePassword(getAuth(getState()), oldPassword, newPassword).then(() => {
			dispatch(receivePasswordChanged(newPassword))
			dispatch(push("/"))
		}
	)
}

export const receivePasswordChanged = createAction("users/passwordChanged", resolve => (newPassword: string) => resolve({newPassword}))
