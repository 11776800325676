import {CisternDto} from "./CisternDto"

export class UserDto {
	constructor(public readonly id: number,
				public readonly displayName: string,
				public readonly cisterns: CisternDto[]) {
	}

	public static fromJson(userObject: any): UserDto {
		return new UserDto(userObject.id, userObject.displayName, Array.from(userObject.cisterns).map(CisternDto.fromJson))
	}
}
