import {RecordType} from "../../api/dto/RecordType"
import {GraphValue} from "../../types/types"
import * as auth from "./auth"
import * as cisterns from "./cisterns"
import * as records from "./records"
import {RootState} from "./reducers"
import * as ui from "./ui";
import * as users from "./users";
import {CisternDto} from "../../api/dto/CisternDto";

export const getUserEmail = (state: RootState) => auth.getUserEmail(state.auth)

export const getCisterns = (state: RootState): CisternDto[] => cisterns.getCisterns(state.cisterns)
export const getCisternById = (state: RootState, cisternId: number): CisternDto | undefined => cisterns.getCisternById(state.cisterns, cisternId)

export const getGraphDataForCistern = (state: RootState, cisternId: number, recordType: RecordType): GraphValue[] => records.getGraphDataForCistern(state.records, cisternId, recordType)
export const getLastRecordDate = (state: RootState, cisternId: number): Date | undefined => records.getLatestRecordDate(state.records, cisternId)

export const isLoggedIn = (state: RootState) => ui.isLoggedIn(state.ui)
export const getSelectedCisternId = (state: RootState) => ui.getSelectedCisternId(state.ui)
export const getSelectedCistern = (state: RootState): CisternDto | undefined => {
	const selectedCisternId = ui.getSelectedCisternId(state.ui)
	return getCisternById(state, selectedCisternId)
}
export const getSelectedViewPortSize = (state: RootState) => ui.getSelectedViewPortSize(state.ui)
export const isDrawerOpen = (state: RootState) => ui.isDrawerOpen(state.ui)

export const getUserDisplayName = (state: RootState) => users.getUserDisplayName(state.users)


