import * as React from 'react'
import WithLegalFooter from "../footer/WithLegalFooter";


const Imprint: React.FC = () => <WithLegalFooter>
	<div style={{padding: '16px'}}>
		<h2>Impressum</h2>

		<h3 id="dsg-general-controller">Verantwortlich für diese Seite</h3>
		<p>
			Andreas Eberle<br/>
			email [at] andreas-eberle [Punkt] com
		</p>

		<h3 id="dsg-general-controller">Inhaltlich Verantwortlicher gemäß § TMG / V.i.S.d.P / § 55 Abs.2 RStV:</h3>
		<p>
			Andreas Eberle
		</p>

		<p>
			Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
			hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
			Spam-Mails, vor.
		</p>

		<h3 id="dsg-general-controller"> Haftung für Inhalte</h3>
		<p>
			Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
			Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
		</p>

		<h3 id="dsg-general-controller">Haftung für Links</h3>
		<p>
			Die Inhalte diesen Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich jedoch keine Gewähr übernehmen.

			Auch übernehme ich trotz sorgfältiger inhaltlicher Kontrolle keine Haftung für die Inhalte externer Links, da es mir nicht möglich ist, diese ständig auf ihre (weiterhin bestehende)
			Rechtmäßigkeit zu überprüfen. Für die Inhalte von auf dieser Webseite verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
			wurden
			zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
			verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Sollten meine Verlinkungen zu gesetzwidrigen, jugendgefährdenden oder aus anderen
			Gründen
			abzulehnenden Darstellungen führen, bitte ich um entsprechende Benachrichtigung. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend entfernen.
		</p>

		<h3 id="dsg-general-controller">Urheberrecht</h3>
		<p>
			Die Inhalte und Werke auf diesen Seiten (Bilder, Texte, Audio- und Videodateien und sonstige Dokumente) wurden, soweit nicht anders gekennzeichnet, von mir selbst angefertigt und
			unterliegen
			dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes ist nur mit meiner schriftlichen
			Genehmigung
			gestattet.

			Soweit die Inhalte auf dieser Seite nicht von mir selbst erstellt wurden, sind die Urheberrechte Dritter beachtet. Insbesondere sind Inhalte Dritter als solche gekennzeichnet. Sollten
			Sie
			trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte umgehend
			entfernen.
		</p>

		<h3 id="dsg-general-controller">Markennamen</h3>
		<p>
			Alle verwendeten und bzw. erwähnten Markenamen und -bezeichnungen sind Eigentum der jeweiligen Markeninhaber.
			Eine etwaige Erwähnung von Marken geschieht gutgläubig und ohne dadurch einen Anspruch ableiten zu wollen.
		</p>

		<h3 id="dsg-general-controller">Datenschutz</h3>
		<p>
			<a href={"/datenschutzerklaerung"}>siehe separate Datenschutzerklärung</a>
		</p>

		<h3 id="dsg-general-controller">Allgemein</h3>
		<p>
			Sollten eine oder mehrere dieser Regelungen ungültig sein, so werden alle anderen nicht in Ihrer Wirksamkeit berührt.
			An Stelle der unwirksamen Regelung tritt die Regelung, die dem Sinn der ursprünglichen Regelung am nächsten kommt.
		</p>
		<br/>
	</div>
</WithLegalFooter>


export default Imprint
