import {CircularProgress} from "@material-ui/core"
import * as React from "react"
import {connect} from "react-redux"
import {RecordType} from "../../api/dto/RecordType"
import * as records from "../../store/actions/records"
import {RootState} from "../../store/reducers/reducers"
import {getGraphDataForCistern} from "../../store/reducers/selectors"
import {ViewPortSize} from "../../types/ViewPortSize"
import Graph from "./Graph"
import {GraphData} from "../../types/GraphData";

interface OwnProps {
	cisternId: number
	recordType: RecordType
	viewPortSize: ViewPortSize
	height: number
}

interface StateProps {
	cisternId: number,
	data: GraphData,
}

interface State {
	readonly fetchingData: boolean
}

interface DispatchProps {
	fetchRecordsForCistern(cisternId: number): any
}

class GraphConnected extends React.Component<StateProps & DispatchProps & OwnProps, State> {
	public readonly state: State = {fetchingData: false}

	public componentDidMount() {
		this.fetchRecords()
	}

	private async fetchRecords() {
		this.setState({fetchingData: true})
		await this.props.fetchRecordsForCistern(this.props.cisternId)
		this.setState({fetchingData: false})
	}

	public render() {
		if (this.props.data.length > 0 || !this.state.fetchingData) {
			return <div>
				<Graph {...this.props}/>
			</div>

		} else {
			return <div style={{flex: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<CircularProgress variant="indeterminate" size={48} value={1} thickness={6}/>
			</div>
		}
	}
}


const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
	cisternId: ownProps.cisternId,
	data: new GraphData(getGraphDataForCistern(state, ownProps.cisternId, ownProps.recordType))
})


const mapDispatchToProps: DispatchProps = {
	fetchRecordsForCistern: records.fetchRecordsForCistern
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(GraphConnected)
