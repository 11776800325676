export enum ViewPortSize {
	DAY = "DAY",
	WEEK = "WEEK",
	MONTH = "MONTH",
}

export const ViewPortSizes = [ViewPortSize.DAY, ViewPortSize.WEEK, ViewPortSize.MONTH]

export const ViewPortSizeLabelMap = new Map<ViewPortSize, string>([
	[ViewPortSize.DAY, 'Tag'],
	[ViewPortSize.WEEK, 'Woche'],
	[ViewPortSize.MONTH, 'Monat'],
])
