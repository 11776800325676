import * as React from "react"
import {FC} from "react"
import {VictoryChart, VictoryChartProps} from 'victory';


type Props = VictoryChartProps;

const IEFriendlyVictoryChart: FC<Props> = (props) => {
	const height = props.height ? props.height : 0
	const width = props.width ? props.width : 1

	return (
		<div style={{position: 'relative', height: 0, width: '100%', padding: 0, paddingBottom: `${100 * (height / width)}%`}}>
			<VictoryChart {...props} style={{...props.style, parent: {position: 'absolute', height: '100%', width: '100%', left: 0, top: 0}}}/>
		</div>
	)
}

export default IEFriendlyVictoryChart;