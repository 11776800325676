import * as React from 'react'
import {Theme, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	text: {
		padding: theme.spacing()
	},
})


const LegalFooter: React.FC<WithStyles<typeof styles>> = ({classes}) => (
	<div className={classes.content}>
		<Link variant="button" color={"inherit"} className={classes.text} component={(props) => <RouterLink {...props} to={"impressum"}/>}>Impressum</Link>
		<Link variant="button" color={"inherit"} className={classes.text} component={(props) => <RouterLink {...props} to={"datenschutzerklaerung"}/>}>Datenschutzerklärung</Link>
	</div>
)

export default withStyles(styles)(LegalFooter)
