import * as React from 'react'
import {Theme, Typography, WithStyles, withStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import {Colors} from "../../utils/Colors";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
	drawerHeader: {
		padding: theme.spacing(),
		display: 'flex',
		flexDirection: 'column',
		height: '164px',
		background: `radial-gradient(farthest-corner at 45px 29px, ${theme.palette.primary.dark} 0%, ${Colors.accent} 100%)`,
		justifyContent: 'flex-end'
	},
	iconNameContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing()
	},
	title: {
		marginLeft: theme.spacing(2)
	},
	text: {
		color: theme.palette.common.white,
	}
})

interface Props {
	email: String
	name: String
}


const WaterManagerDrawerHeader: React.FC<Props & WithStyles<typeof styles>> = (props) => {
	const {classes, name, email} = props;


	return (
		<div className={classes.drawerHeader}>
			<div>
				<div className={classes.iconNameContainer}>
					<img src={"/assets/logo_foreground.png"} alt={""} height={'64dp'}/>
					<Typography className={classNames(classes.text, classes.title)} variant={"h6"}>Wassermanager</Typography>
				</div>
			</div>
			<div><Typography className={classes.text} variant={"body2"}>{name}</Typography></div>
			<div><Typography className={classes.text} variant={"caption"}>{email}</Typography></div>
		</div>
	)
}

export default withStyles(styles)(WaterManagerDrawerHeader)