import {combineReducers} from "redux"
import {getType} from "typesafe-actions"
import {WaterManagerActions} from "../actions/actions"
import {logout, receiveLoginSuccess, receivePasswordChanged} from "../actions/users"


export interface AuthState {
	readonly email: string
	readonly password: string
	readonly persistCredentials: boolean
}

const email = (state: string = "", action: WaterManagerActions): string => {
	switch (action.type) {
		case getType(receiveLoginSuccess):
			return action.payload.email

		case getType(logout):
			return ""

		default:
			return state
	}
}

const password = (state: string = "", action: WaterManagerActions): string => {
	switch (action.type) {
		case getType(receiveLoginSuccess):
			return action.payload.password

		case getType(receivePasswordChanged):
			return action.payload.newPassword

		case getType(logout):
			return ""

		default:
			return state
	}
}

const persistCredentials = (state: boolean = false, action: WaterManagerActions): boolean => {
	switch (action.type) {
		case getType(receiveLoginSuccess):
			return action.payload.persistCredentials

		case getType(logout):
			return false

		default:
			return state
	}
}


export const authReducers = combineReducers<AuthState, WaterManagerActions>({
	email,
	password,
	persistCredentials
})


export const getUserEmail = (state: AuthState): string => state.email