import {combineReducers} from "redux"
import {getType} from "typesafe-actions"
import {WaterManagerActions} from "../actions/actions"
import {logout, receiveUserData} from "../actions/users"


export interface UsersState {
	readonly id: number
	readonly displayName: string
}

const id = (state: number = -1, action: WaterManagerActions): number => {
	switch (action.type) {
		case getType(receiveUserData) :
			return action.payload.user.id

		case getType(logout) :
			return -1

		default:
			return state
	}
}

const displayName = (state: string = "", action: WaterManagerActions): string => {
	switch (action.type) {
		case getType(receiveUserData) :
			return action.payload.user.displayName

		case getType(logout) :
			return ""

		default:
			return state
	}
}

export const usersReducers = combineReducers<UsersState, WaterManagerActions>({
	id,
	displayName
})


export const getUserId = (state: UsersState) => state.id
export const getUserDisplayName = (state: UsersState) => state.displayName