import {Button, TextField, Theme, Typography, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'

import * as React from 'react'


const styles = (theme: Theme) => createStyles({
	root: {
		paddingTop: '10%'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	heading: {
		paddingBottom: theme.spacing(4),
		textAlign: 'center',
	},
	button: {
		marginTop: theme.spacing(5),
	},
	textField: {
		width: 300,
		paddingBottom: theme.spacing(2),
	},
	errorText: {
		color: theme.palette.error.main,
		textAlign: 'center',
	}
})

interface Props {
	changePassword(oldPassword: string, newPassword: string): Promise<void>
}

interface State {
	readonly oldPassword: string
	readonly newPassword: string
	readonly newPasswordRepeat: string

	readonly alreadySubmitted: boolean
	readonly changePasswordFailed: boolean
	readonly newPasswordsNotEqual: boolean
}

const initialState = {
	oldPassword: '',
	newPassword: '',
	newPasswordRepeat: '',

	alreadySubmitted: false,
	changePasswordFailed: false,
	newPasswordsNotEqual: false,
}


class PasswordChangePage extends React.Component <Props & WithStyles<typeof styles>, State> {

	public readonly state: State = initialState

	public render() {
		const classes = this.props.classes

		return (
			<div className={classes.root}>
				<form onSubmit={this.handleSubmit}>
					<div className={classes.container}>
						<Typography variant='h4' className={classes.heading}>Passwort ändern</Typography>

						<TextField id='oldPassword' type='password' label='Bisheriges Passwort' className={classes.textField}
								   error={this.state.alreadySubmitted && !this.state.oldPassword} onChange={this.handleUpdateOldPassword}/>
						<TextField id='newPassword' type='password' label='Neues Passwort' className={classes.textField}
								   error={this.state.alreadySubmitted && (!this.state.newPassword || this.state.newPasswordsNotEqual)} onChange={this.handleUpdateNewPassword}/>
						<TextField id='repeatNewPassword' type='password' label='Neues Passwort wiederholen' className={classes.textField}
								   error={this.state.alreadySubmitted && (!this.state.newPasswordRepeat || this.state.newPasswordsNotEqual)} onChange={this.handleUpdateNewPasswordRepeat}/>

						{this.getErrorText()}

						<Button variant='contained' color='primary' size='small' className={classes.button} type='submit'>Passwort ändern</Button>
					</div>
				</form>
			</div>
		)
	}

	private getErrorText() {
		if (this.state.changePasswordFailed) {
			return <Typography className={this.props.classes.errorText}>
				Das Passwort konnte nicht geändert werden.<br/>
				Bitte überprüfen Sie ihr bisheriges Passwort.
			</Typography>
		} else if (this.state.newPasswordsNotEqual) {
			return <Typography className={this.props.classes.errorText}>
				Das neue Passwort stimmt nicht mit der Wiederholung überein.<br/>
				Bitte stellen Sie sicher, dass das neue Passwort korrekt wiederholt wird.
			</Typography>
		} else {
			return <div/>
		}
	}

	private handleUpdateOldPassword = (e: React.ChangeEvent<HTMLInputElement>) => this.setState(updateOldPassword(e.currentTarget.value))
	private handleUpdateNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => this.setState(updateNewPassword(e.currentTarget.value))
	private handleUpdateNewPasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => this.setState(updateNewPasswordRepeat(e.currentTarget.value))

	private handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()

		this.setState({alreadySubmitted: true})

		if (this.isFormFilled()) {
			if (this.areNewPasswordsEqual()) {
				try {
					await this.props.changePassword(this.state.oldPassword, this.state.newPassword)
				} catch (error) {
					this.setState({changePasswordFailed: true})
				}


			} else {
				this.setState({newPasswordsNotEqual: true})
			}
		}
	}

	private areNewPasswordsEqual() {
		return this.state.newPassword === this.state.newPasswordRepeat
	}

	private isFormFilled() {
		return this.state.oldPassword && this.state.newPassword && this.state.newPasswordRepeat
	}
}

const updateOldPassword = (password: string) => ({oldPassword: password, changePasswordFailed: false})
const updateNewPassword = (password: string) => ({newPassword: password, changePasswordFailed: false})
const updateNewPasswordRepeat = (password: string) => ({newPasswordRepeat: password, changePasswordFailed: false})


export default withStyles(styles)(PasswordChangePage)
