import {RecordType} from "./RecordType"
import {BoundRecordType} from "./BoundRecordType";

export class CisternDto {

    constructor(public readonly id: number,
                public readonly cisternName: string,
                public readonly recordTypes: RecordType[]) {
    }

    public static fromJson(cisternObject: any): CisternDto {
        return new CisternDto(cisternObject.id, cisternObject.cistern_name, cisternObject.record_types.map(({record_type}: BoundRecordType) => record_type))
    }
}
