import {combineReducers} from "redux"
import {getType} from "typesafe-actions"
import {WaterManagerActions} from "../actions/actions"
import {logout, receiveLoginSuccess, receiveUserData} from "../actions/users"
import {setDrawerState, setSelectedCisternId, setSelectedViewPortSize, toggleDrawerState} from "../actions/ui";
import {CisternDto} from "../../api/dto/CisternDto";
import {ViewPortSize} from "../../types/ViewPortSize";


export interface UiState {
	readonly loggedIn: boolean
	readonly selectedCisternId: number
	readonly selectedViewPortSize: ViewPortSize
	readonly drawerOpen: boolean
}

const loggedIn = (state: boolean = false, action: WaterManagerActions): boolean => {
	switch (action.type) {
		case getType(receiveLoginSuccess) :
			return true

		case getType(logout) :
			return false

		default:
			return state
	}
}

const selectedCisternId = (state: number = -1, action: WaterManagerActions): number => {
	switch (action.type) {
		case getType(setSelectedCisternId):
			return action.payload.cisternId

		case getType(receiveUserData): {
			const newCisternIds = action.payload.user.cisterns
				.sort((a, b) => a.cisternName.localeCompare(b.cisternName))
				.map((cisternDto: CisternDto) => cisternDto.id)

			if (newCisternIds.length <= 0) {
				return -1
			} else if (!newCisternIds.includes(state)) {
				return newCisternIds[0]
			} else {
				return state
			}
		}

		default:
			return state
	}
}
const selectedViewPortSize = (state: ViewPortSize = ViewPortSize.DAY, action: WaterManagerActions): ViewPortSize => {
	switch (action.type) {
		case getType(setSelectedViewPortSize):
			return action.payload.viewPortSize

		default:
			return state
	}
}

const drawerOpen = (state: boolean = false, action: WaterManagerActions): boolean => {
	switch (action.type) {
		case getType(setDrawerState):
			return action.payload.drawerState

		case getType(toggleDrawerState):
			return !state

		case getType(logout):
			return false

		default:
			return state
	}
}

export const uiReducer = combineReducers<UiState, WaterManagerActions>({
	loggedIn,
	selectedCisternId,
	selectedViewPortSize,
	drawerOpen
})


export const isLoggedIn = (state: UiState): boolean => state.loggedIn
export const getSelectedCisternId = (state: UiState): number => state.selectedCisternId
export const getSelectedViewPortSize = (state: UiState): ViewPortSize => state.selectedViewPortSize
export const isDrawerOpen = (state: UiState): boolean => state.drawerOpen