import {Dispatch} from "redux"
import {createAction} from "typesafe-actions"
import * as BackendApi from "../../api/BackendApi"
import {WaterLevelRecordDto} from "../../api/dto/WaterLevelRecordDto"
import {getLastRecordDate} from "../reducers/selectors"
import {getAuth} from "../../utils/utils";
import {getStateType} from "../store";

const PAGE_SIZE = 512

export const fetchRecordsForCistern = (cisternId: number) => async (dispatch: Dispatch, getState: getStateType) => {
    const state = getState()

    let finished: boolean
    let page = 0

    const cutOffDate = getCutOffDate().getTime()

    do {
        const records = await BackendApi.getRecords(getAuth(state), cisternId, page, PAGE_SIZE, getLastRecordDate(state, cisternId))
        dispatch(receiveRecords(cisternId, records))

        page++
        finished = records.length >= PAGE_SIZE && records[records.length - 1].date.getTime() >= cutOffDate
    } while (finished)
}

export const receiveRecords = createAction("records/receiveRecords", resolve => (cisternId: number, records: WaterLevelRecordDto[]) => resolve({cisternId, records}))


function getCutOffDate(): Date {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 2)
    return oneMonthAgo
}