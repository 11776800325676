export enum RecordType {
	WATER_DISTANCE = "WATER_DISTANCE",
	WATER_HEIGHT = "WATER_HEIGHT",
	WATER_VOLUME = "WATER_VOLUME",
	WATER_LEVEL_PERCENT = "WATER_LEVEL_PERCENT",

	BATTERY_VOLTAGE = "BATTERY_VOLTAGE",
	BATTERY_LEVEL_PERCENT = "BATTERY_LEVEL_PERCENT",

	TEMPERATURE = "TEMPERATURE",
	HUMIDITY = "HUMIDITY"
}

export const RecordTypeIconMap = new Map<RecordType, string>([
	[RecordType.WATER_DISTANCE, '/assets/record-types/icons/water_distance.png'],
	[RecordType.WATER_HEIGHT, '/assets/record-types/icons/water_height.png'],
	[RecordType.WATER_VOLUME, '/assets/record-types/icons/water_volume.png'],
	[RecordType.WATER_LEVEL_PERCENT, '/assets/record-types/icons/water_level_percent.png'],

	[RecordType.BATTERY_VOLTAGE, '/assets/record-types/icons/battery_voltage.png'],
	[RecordType.BATTERY_LEVEL_PERCENT, '/assets/record-types/icons/battery_level_percent.png'],

	[RecordType.TEMPERATURE, '/assets/record-types/icons/temperature.png'],
	[RecordType.HUMIDITY, '/assets/record-types/icons/humidity.png'],
])

export const RecordTypeLabelMap = new Map<RecordType, string>([
	[RecordType.WATER_DISTANCE, 'Wasserdistanz [cm]'],
	[RecordType.WATER_HEIGHT, 'Füllstand [cm]'],
	[RecordType.WATER_VOLUME, 'Füllstand [m³]'],
	[RecordType.WATER_LEVEL_PERCENT, 'Füllstand [%]'],

	[RecordType.BATTERY_VOLTAGE, 'Spannung [V]'],
	[RecordType.BATTERY_LEVEL_PERCENT, 'Batterie [%]'],

	[RecordType.TEMPERATURE, 'Temperatur [°C]'],
	[RecordType.HUMIDITY, 'Feuchtigkeit [%]'],
])
