import {applyMiddleware, createStore, Middleware} from "redux"
import createLogger from "redux-logger"
import thunk from "redux-thunk"
import {createRootReducer, RootState} from "./reducers/reducers"
import {createBrowserHistory} from 'history'
import {routerMiddleware} from "connected-react-router";
import {loadState} from "./localStorage";

export type getStateType = () => RootState

export const history = createBrowserHistory()

function configureStore() {
	const middlewares: Middleware[] = [thunk, routerMiddleware(history)]

	if (process.env.NODE_ENV !== 'production') {
		middlewares.push(createLogger)
	}

	return createStore(
		createRootReducer(history),
		loadState(),
		applyMiddleware(...middlewares)
	)
}

export const store = configureStore()