import {GraphValue} from "./types";

export class GraphData {
	public readonly minDate: Date
	public readonly maxDate: Date
	public readonly minValue: number
	public readonly maxValue: number

	constructor(public readonly values: GraphValue[]) {
		if (values.length === 0) {
			this.minDate = this.maxDate = new Date()
			this.minValue = this.maxValue = 0
			return
		}

		let minDate = values[0].date;
		let maxDate = values[0].date;

		let minValue = values[0].value;
		let maxValue = values[0].value;

		values.forEach(value => {
			if (value.date < minDate) {
				minDate = value.date
			} else if (value.date > maxDate) {
				maxDate = value.date
			}
			if (value.value < minValue) {
				minValue = value.value
			} else if (value.value > maxValue) {
				maxValue = value.value
			}
		})

		this.minDate = minDate
		this.maxDate = maxDate
		this.minValue = minValue
		this.maxValue = maxValue
	}

	public get length(): number {
		return this.values.length
	}
}
