import * as React from "react"
import {ReactElement} from "react"
import {connect} from "react-redux"
import * as uiActions from "../../store/actions/ui"
import AppBarWithDrawer from "./AppBarWithDrawer";
import {RootState} from "../../store/reducers/reducers";
import {isLoggedIn} from "../../store/reducers/selectors";


interface OwnProps {
	children: ReactElement
}

interface StateProps {
	loggedIn: Boolean
}

interface DispatchProps {
	toggleDrawerState(): void
}

const WaterManagerDrawerConnected: React.FC<StateProps & DispatchProps & OwnProps> = (props) => <AppBarWithDrawer {...props}/>


const mapStateToProps = (state: RootState): StateProps => ({
	loggedIn: isLoggedIn(state),
})

export default connect<StateProps, DispatchProps>(mapStateToProps, uiActions)(WaterManagerDrawerConnected)
