import axios, {AxiosInstance} from 'axios'
import {UserDto} from "./dto/UserDto"
import {mapToObjectArray} from "./dto/Utils"
import {WaterLevelRecordDto} from "./dto/WaterLevelRecordDto"
import {Auth} from "../types/types";


const HTTP_STATUS_OK = 200
const HTTP_STATUS_NO_CONTENT = 204

const backend = createAxios()

function createAxios(): AxiosInstance {
	return axios.create({
		baseURL: process.env.REACT_APP_BACKEND_URL,
		timeout: 25000,
		headers: {"Content-Type": "application/json"}
	})
}

export async function getUserData(auth: Auth): Promise<UserDto> {
	const response = await backend.get<any>('/user', {auth})

	if (response.status !== HTTP_STATUS_OK) {
		throw new Error("Login failed")
	}

	return UserDto.fromJson(response.data)
}

export async function getRecords(auth: Auth, cisternId: number, page: number, pageSize: number, startDate?: Date): Promise<WaterLevelRecordDto[]> {
	let url = `/cisterns/${cisternId}/records?page=${page}&size=${pageSize}&sort=DESC`
	if (startDate) {
		url += `&startDate=${startDate.toISOString()}`
	}
	const response = await backend.get <any>(url, {auth})

	if (response.status !== HTTP_STATUS_OK) {
		throw new Error(`Failed retrieving records for cisternId: ${cisternId}`)
	}

	return mapToObjectArray(response.data.records, WaterLevelRecordDto.fromJson)
}

export async function changePassword(auth: Auth, oldPassword: string, newPassword: string) {
	const response = await backend.put <any[]>(`/user/password`, {oldPassword, newPassword}, {auth})

	if (response.status !== HTTP_STATUS_NO_CONTENT) {
		throw new Error(`Failed changing password.`)
	}
}
