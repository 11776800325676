import * as React from "react"
import {connect} from "react-redux"
import {CisternDto} from "../../api/dto/CisternDto"
import {RootState} from "../../store/reducers/reducers"
import {getCisterns, getSelectedCisternId} from "../../store/reducers/selectors"
import CisternSelector from "./CisternSelector";
import * as uiActions from "../../store/actions/ui"

interface StateProps {
	cisterns: CisternDto[],
	selectedCisternId: number,
}

interface DispatchProps {
	setSelectedCisternId(cisternId: number): void
}

const CisternSelectorConnected: React.FC<StateProps & DispatchProps> = (props) => <CisternSelector {...props}/>


const mapStateToProps = (state: RootState): StateProps => ({
	cisterns: getCisterns(state),
	selectedCisternId: getSelectedCisternId(state)
})

export default connect<StateProps,DispatchProps>(mapStateToProps,uiActions)(CisternSelectorConnected)
