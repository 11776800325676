import {connect} from "react-redux"
import {changePassword} from "../../store/actions/users"
import PasswordChangePage from "./PasswordChangePage";
import {bindActionCreators, Dispatch} from "redux";
import {WaterManagerActions} from "../../store/actions/actions";


interface DispatchProps {
	changePassword(email: string, password: string): any
}

const mapDispatchToProps = (dispatch: Dispatch<WaterManagerActions>) => bindActionCreators({
		changePassword,
	},
	dispatch
);

export default connect<{}, DispatchProps>(null, mapDispatchToProps)(PasswordChangePage)
