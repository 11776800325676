import * as React from 'react'
import {ReactElement} from 'react'
import {AppBar, IconButton, Toolbar, WithStyles} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import {createStyles, withStyles} from '@material-ui/core/styles'
import WaterManagerDrawerConnected from "../drawer/WaterManagerDrawerConnected"
import {Link as RouterLink} from "react-router-dom"
import Link from "@material-ui/core/Link"


const styles = () => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%',
		height: '100%',
	},
	appBar: {},
	menuButton: {
		marginRight: 20,
	},
})

interface Props {
	loggedIn: Boolean
	children: ReactElement

	toggleDrawerState(): void
}


const AppBarWithDrawer: React.FC<Props & WithStyles<typeof styles>> = (props) => {
	const {loggedIn, classes} = props;

	return <div className={classes.root}>
		<AppBar position="sticky" className={classes.appBar}>
			<Toolbar>
				{loggedIn && <IconButton color="inherit" aria-label="Open drawer" onClick={props.toggleDrawerState} className={classes.menuButton}> <MenuIcon/> </IconButton>}
				<Link variant="h6" color="inherit" noWrap to={"/"} component={RouterLink}>Wassermanager</Link>
			</Toolbar>
		</AppBar>

		{loggedIn && <WaterManagerDrawerConnected/>}

		{props.children}
	</div>
}

export default withStyles(styles)(AppBarWithDrawer)
