import * as React from 'react'
import {ReactElement} from 'react'
import {WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'
import LegalFooter from "./LegalFooter";

const styles = () => createStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flex:'1 1 100%',
	},
})

interface Props {
	children: ReactElement
}

const WithLegalFooter: React.FC<Props & WithStyles<typeof styles>> = ({children, classes}) => (
	<div className={classes.container}>
		{children}
		<LegalFooter/>
	</div>
)

export default withStyles(styles)(WithLegalFooter)
