import * as React from 'react'
import {SwipeableDrawer, Theme, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'
import CisternSelectorConnected from "./CisternSelectorConnected";
import ViewPortSizeSelectorConnected from "./ViewPortSizeSelectorConnected";
import WaterManagerDrawerHeaderConnected from "./WaterManagerDrawerHeaderConnected";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

const drawerWidth = 300;

const styles = (theme: Theme) => createStyles({
	drawerContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	drawerPaper: {
		width: drawerWidth,
	},
	divider: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
	},
})

interface Props {
	open: boolean

	setDrawerState(open: boolean): void

	onLogout(): void

	onShowChangePassword(): void
}


const WaterManagerDrawer: React.FC<Props & WithStyles<typeof styles>> = (props) => {
	const {classes, open, setDrawerState, onLogout, onShowChangePassword} = props;

	return (
		<SwipeableDrawer
			variant="temporary"
			open={open}
			onOpen={() => setDrawerState(true)}
			onClose={() => setDrawerState(false)}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.drawerContent}>
				<WaterManagerDrawerHeaderConnected/>

				<CisternSelectorConnected/>
				<ViewPortSizeSelectorConnected/>

				<Divider className={classes.divider}/>

				<List>
					<ListItem button onClick={onShowChangePassword}><Typography>Passwort ändern</Typography></ListItem>
					<ListItem button onClick={onLogout}><Typography>Abmelden</Typography></ListItem>
				</List>
			</div>
		</SwipeableDrawer>
	)
}

export default withStyles(styles)(WaterManagerDrawer)
