import {Theme, WithStyles} from "@material-ui/core"
import {createStyles} from "@material-ui/core/styles"
import withStyles from "@material-ui/core/styles/withStyles"
import * as React from "react"
import {ReactChild} from "react"
import {Route, Switch} from "react-router"
import LoginPageConnected from "./components/login/LoginPageConnected"
import OverviewPageConnected from "./components/overview/OverviewPageConnected";
import PasswordChangePageConnected from "./components/password/PasswordChangePageConnected";
import AppBarWithDrawerConnected from "./components/appbar/AppBarWithDrawerConnected";
import Imprint from "./components/imprint/Imprint";
import DataPrivacyInfo from "./components/imprint/DataPrivacyInfo";


const styles = (theme: Theme) => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	flex: {
		flex: 1,
	},
	content: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		height: '100%'
	}
})

interface Props {
	loggedIn: boolean

	checkLogin(): void
}

class App extends React.Component<Props & WithStyles<typeof styles>> {

	public render() {
		return (
			<AppBarWithDrawerConnected>
				<Switch>
					<Route path="/impressum" component={Imprint}/>
					<Route path="/datenschutzerklaerung" component={DataPrivacyInfo}/>

					<Route path="/login" component={LoginPageConnected}/>
					<Route path="/password" render={this.requireLoggedInUser(() => <PasswordChangePageConnected/>)}/>
					<Route path="/" render={this.requireLoggedInUser(() => <OverviewPageConnected/>)}/>

				</Switch>
			</AppBarWithDrawerConnected>
		)
	}

	private requireLoggedInUser = (contentProvider: () => ReactChild) => () => {
		if (this.props.loggedIn) {
			return contentProvider()
		} else {
			this.props.checkLogin()
			return <div/>
		}
	}
}

export default withStyles(styles)(App)
