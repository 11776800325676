import * as React from 'react'
import {ChangeEvent} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, Theme, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'
import {CisternDto} from "../../api/dto/CisternDto";

const styles = (theme: Theme) => createStyles({
	container: {
		padding: theme.spacing(2)
	},
	formControl: {
		width: '100%'
	},
})

interface Props {
	cisterns: CisternDto[],
	selectedCisternId: number,

	setSelectedCisternId(cisternId: number): void
}

const CisternSelector: React.FC<Props & WithStyles<typeof styles>> = (props) => {
	const {classes, cisterns, selectedCisternId, setSelectedCisternId} = props;

	const onCisternNameChanged = (event: ChangeEvent<HTMLSelectElement>) => {
		setSelectedCisternId(Number(event.target.value))
	}

	if (cisterns.length > 1) {
		return <div className={classes.container}>
			<FormControl className={classes.formControl}>
				<InputLabel htmlFor="name-disabled">Brunnestub</InputLabel>
				<Select
					value={selectedCisternId}
					onChange={onCisternNameChanged}
					input={<Input name="cisternName" id="cisternName"/>}
				>
					{cisterns.map((cistern) => <MenuItem value={cistern.id} key={cistern.id}>{cistern.cisternName}</MenuItem>)}
				</Select>
			</FormControl>
		</div>
	} else {
		return <div/>
	}
}

export default withStyles(styles)(CisternSelector)
