import * as React from 'react'
import {ChangeEvent} from 'react'
import {FormControl, Input, InputLabel, MenuItem, Select, Theme, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'
import {ViewPortSize, ViewPortSizeLabelMap, ViewPortSizes} from "../../types/ViewPortSize";

const styles = (theme: Theme) => createStyles({
	container: {
		padding: theme.spacing(2)
	},
	formControl: {
		width: '100%'
	},
})

interface Props {
	selectedViewPortSize: ViewPortSize,

	setSelectedViewPortSize(viewPortSize: ViewPortSize): void
}

const ViewPortSizeSelector: React.FC<Props & WithStyles<typeof styles>> = (props) => {
	const {classes, selectedViewPortSize, setSelectedViewPortSize} = props;

	const onViewPortSizeChanged = (event: ChangeEvent<HTMLSelectElement>) => {
		setSelectedViewPortSize(event.target.value as ViewPortSize)
	}

	return <div className={classes.container}>
		<FormControl className={classes.formControl}>
			<InputLabel htmlFor="name-disabled">Zeitraum</InputLabel>
			<Select
				value={selectedViewPortSize}
				onChange={onViewPortSizeChanged}
				input={<Input name="viewPortSize" id="viewPortSize"/>}
			>
				{ViewPortSizes.map((viewPortSize) => <MenuItem value={viewPortSize} key={viewPortSize}>{ViewPortSizeLabelMap.get(viewPortSize)}</MenuItem>)}
			</Select>
		</FormControl>
	</div>
}

export default withStyles(styles)(ViewPortSizeSelector)
