import {combineReducers} from "redux"
import {WaterManagerActions} from "../actions/actions"
import * as auth from "./auth"
import * as cisterns from "./cisterns"
import * as records from "./records"
import * as users from "./users"
import * as ui from "./ui"
import {connectRouter, RouterState} from "connected-react-router"
import {History} from 'history'

export interface RootState {
	readonly users: users.UsersState
	readonly auth: auth.AuthState
	readonly cisterns: cisterns.CisternsState
	readonly records: records.RecordsState
	readonly ui: ui.UiState
	readonly router: RouterState
}

export const createRootReducer = (history: History) => combineReducers<RootState, WaterManagerActions>({
	users: users.usersReducers,
	auth: auth.authReducers,
	cisterns: cisterns.cisternsReducers,
	records: records.recordsReducers,
	ui: ui.uiReducer,
	router: connectRouter(history)
})