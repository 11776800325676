import {connect} from "react-redux"
import {loginUser} from "../../store/actions/users"
import LoginPage from "./LoginPage"
import {bindActionCreators, Dispatch} from "redux";
import {WaterManagerActions} from "../../store/actions/actions";


interface DispatchProps {
	loginUser(email: string, password: string, persistCredentials: boolean): any  // This any is needed as the types will not match otherwise
}

const mapDispatchToProps = (dispatch: Dispatch<WaterManagerActions>) => bindActionCreators({
		loginUser,
	},
	dispatch
);

export default connect<{}, DispatchProps>(null, mapDispatchToProps)(LoginPage)
