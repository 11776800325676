import * as React from "react"
import {connect} from "react-redux"
import {RootState} from "../../store/reducers/reducers"
import * as uiActions from "../../store/actions/ui"
import ViewPortSizeSelector from "./ViewPortSizeSelector";
import {ViewPortSize} from "../../types/ViewPortSize";
import {getSelectedViewPortSize} from "../../store/reducers/selectors";

interface StateProps {
	selectedViewPortSize: ViewPortSize,
}

interface DispatchProps {
	setSelectedViewPortSize(viewPortSize: ViewPortSize): void
}

const ViewPortSizeSelectorConnected: React.FC<StateProps & DispatchProps> = (props) => <ViewPortSizeSelector {...props}/>

const mapStateToProps = (state: RootState): StateProps => ({
	selectedViewPortSize: getSelectedViewPortSize(state)
})

export default connect<StateProps, DispatchProps>(mapStateToProps, uiActions)(ViewPortSizeSelectorConnected)
