import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import {createTheme, ThemeProvider} from "@material-ui/core/styles"
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from "react-redux"
import AppConnected from "./AppConnected"
import {history, store} from "./store/store"
import {ConnectedRouter} from "connected-react-router"
import {CssBaseline} from "@material-ui/core"
import * as serviceWorker from "./registerServiceWorker"

const appTheme = createTheme({
	palette: {
		primary: {
			light: '#BBDEFB',
			main: '#3074fd',
			dark: '#1760bf',
			contrastText: '#ffffff',
		},
		secondary: {
			light: '#eff4ff',
			main: '#c4d6e8',
			dark: '#8a8fae',
			contrastText: '#757575',
		},
	},
})


ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<ThemeProvider theme={appTheme}>
				<CssBaseline/>
				<AppConnected/>
			</ThemeProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root') as HTMLElement
)

serviceWorker.unregister()
