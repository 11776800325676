import {combineReducers} from "redux"
import {getType} from "typesafe-actions"
import {RecordType} from "../../api/dto/RecordType"
import {WaterLevelRecordDto} from "../../api/dto/WaterLevelRecordDto"
import {GraphValue} from "../../types/types"
import {WaterManagerActions} from "../actions/actions"
import {receiveRecords} from "../actions/records"
import {logout} from "../actions/users"


interface RecordsByCisternId {
	readonly [cisternId: number]: WaterLevelRecordDto[]
}

export interface RecordsState {
	readonly recordsByCisternId: RecordsByCisternId
}

const recordsByCisternId = (state: RecordsByCisternId = {}, action: WaterManagerActions): RecordsByCisternId => {
	switch (action.type) {
		case getType(receiveRecords): {
			const cisternId = action.payload.cisternId
			const newRecords = action.payload.records

			if (newRecords.length === 0) {
				return state
			}

			const newState = {...state}
			newState[cisternId] = mergeRecords(state[cisternId], newRecords)
			return newState
		}

		case getType(logout):
			return {}

		default:
			return state
	}
}

function mergeRecords(existingRecords: WaterLevelRecordDto[], newRecords: WaterLevelRecordDto[]): WaterLevelRecordDto[] {
	existingRecords = existingRecords ? existingRecords : []
	const existingIds = existingRecords.map(record => record.id)
	const reallyNewRecords = newRecords.filter(record => !existingIds.includes(record.id))
	return [...existingRecords, ...reallyNewRecords].sort((a: WaterLevelRecordDto, b: WaterLevelRecordDto) => b.date.getTime() - a.date.getTime())
}

export const recordsReducers = combineReducers<RecordsState, WaterManagerActions>({
	recordsByCisternId,
})


const getRecordsForCistern = (state: RecordsState, cisternId: number): WaterLevelRecordDto[] => {
	const records = state.recordsByCisternId[cisternId]
	return records ? records : []
}

export const getGraphDataForCistern = (state: RecordsState, cisternId: number, recordType: RecordType): GraphValue[] => {
	const records = getRecordsForCistern(state, cisternId)
	return records.map(record => ({date: record.date, value: record.getValueForRecordType(recordType)})).filter(it => it.value !== undefined)
}

export function getLatestRecordDate(state: RecordsState, cisternId: number): Date | undefined {
	const records = getRecordsForCistern(state, cisternId)

	if (records.length > 0) {
		return records.map(record => record.date)[0]
	} else {
		return undefined
	}
}
