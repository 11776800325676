import {Tab, Tabs, Theme, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'
import * as React from "react"
import {ChangeEvent} from "react"
import {MeasuredComponentProps, withContentRect} from "react-measure"
import {CisternDto} from "../../api/dto/CisternDto"
import {RecordType, RecordTypeIconMap, RecordTypeLabelMap} from "../../api/dto/RecordType"
import GraphConnected from "../graph/GraphConnected"
import {ViewPortSize} from "../../types/ViewPortSize";


const styles = (theme: Theme) => createStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: '150px'
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: theme.spacing(2),
	}
})

interface Props {
	selectedCistern: CisternDto
	selectedViewPortSize: ViewPortSize
}

interface State {
	readonly selectedRecordTypeIndex: number
}

class OverviewPage extends React.Component <Props & WithStyles<typeof styles> & MeasuredComponentProps, State> {
	public readonly state: State = {
		selectedRecordTypeIndex: 0,
	}

	private get chartHeight(): number {
		return window.innerHeight - 164
	}

	public render() {
		const {classes, selectedCistern, selectedViewPortSize} = this.props

		return <div className={classes.container} ref={this.props.measureRef}>
			<GraphConnected key={selectedCistern.id}
							cisternId={selectedCistern.id}
							recordType={this.selectedRecordType()}
							viewPortSize={selectedViewPortSize}
							height={this.chartHeight}/>
			<div className={classes.footer}>
				{this.renderButtonBar()}
			</div>
		</div>
	}

	private renderButtonBar() {
		return <Tabs
			value={this.state.selectedRecordTypeIndex}
			onChange={this.onRecordTypeChanged}
			indicatorColor="primary"
			textColor="primary"
			variant={"scrollable"}
			scrollButtons={"on"}
		>
			{this.props.selectedCistern.recordTypes.map(recordType =>
				<Tab key={recordType} label={RecordTypeLabelMap.get(recordType)} icon={<img src={RecordTypeIconMap.get(recordType)} alt={""} height={'22px'}/>}/>)}
		</Tabs>
	}

	private onRecordTypeChanged = (_: ChangeEvent, value: number) => {
		this.setState({selectedRecordTypeIndex: value})
	}

	private selectedRecordType = (): RecordType => {
		return this.props.selectedCistern.recordTypes[this.state.selectedRecordTypeIndex]
	}
}

export default withStyles(styles)(withContentRect('bounds')(OverviewPage))
