import {Button, TextField, Theme, Typography, WithStyles} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'

import * as React from 'react'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import WithLegalFooter from "../footer/WithLegalFooter";


const styles = (theme: Theme) => createStyles({
	container: {
		paddingTop: '5vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	formContainer: {
		paddingTop: theme.spacing(),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start'
	},
	heading: {
		paddingBottom: theme.spacing(5),
		textAlign: 'center',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4)
	},
	textField: {
		width: 300,
		paddingBottom: theme.spacing(2),
	},
	checkbox: {
		paddingBottom: theme.spacing(2),
	},
	errorText: {
		color: theme.palette.error.main,
		textAlign: 'center',
	}
})

interface Props {
	loginUser(email: string, password: string, persistCredentials: boolean): Promise<void>
}

const initialState = {
	email: '',
	password: '',
	persistCredentials: false,
	alreadySubmitted: false,
	loginFailed: false,
}

type State = Readonly<typeof initialState>


class LoginPage extends React.Component <Props & WithStyles<typeof styles>, State> {

	public readonly state: State = initialState

	public render() {
		const classes = this.props.classes

		return <>
			<WithLegalFooter>
				<form onSubmit={this.handleSubmit}>
					<div className={classes.container}>
						<img src={'assets/logo.png'} width={'100px'} alt={"logo"}/>

						<div className={classes.formContainer}>
							<TextField id='email' label='Email' autoFocus value={this.state.email} className={classes.textField} error={this.state.alreadySubmitted && !this.state.email}
									   onChange={this.handleUpdateEmail}/>
							<TextField id='password' type='password' label='Password' className={classes.textField} error={this.state.alreadySubmitted && !this.state.password}
									   onChange={this.handleUpdatePassword}/>

							<FormControlLabel control={<Checkbox color="primary" onChange={this.handlePersistCredentialsChanged} checked={this.state.persistCredentials}/>}
											  label="Angemeldet bleiben?"/>

							{this.getErrorText()}
						</div>

						<Button variant='contained' color='primary' size='small' className={classes.button} type='submit'>Anmelden</Button>
					</div>
				</form>
			</WithLegalFooter>
		</>
	}

	private getErrorText() {
		if (this.state.loginFailed) {
			return <Typography className={this.props.classes.errorText}>
				Die E-Mailadresse oder das Passwort ist falsch.<br/>
				Bitte überprüfen Sie ihre Anmeldedaten.
			</Typography>
		} else {
			return <div/>
		}
	}

	private handleUpdateEmail = (e: React.ChangeEvent<HTMLInputElement>) => this.setState(updateEmail(e.currentTarget.value))

	private handleUpdatePassword = (e: React.ChangeEvent<HTMLInputElement>) => this.setState(updatePassword(e.currentTarget.value))

	private handlePersistCredentialsChanged = () => this.setState({persistCredentials: !this.state.persistCredentials})


	private handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		this.setState({alreadySubmitted: true})

		if (this.isFormFilled()) {
			try {
				await this.props.loginUser(this.state.email, this.state.password, this.state.persistCredentials)
			} catch (error) {
				this.setState({loginFailed: true})
			}
		}
	}

	private isFormFilled() {
		return this.state.email && this.state.password
	}
}

const updateEmail = (email: string) => ({email: email, loginFailed: false})
const updatePassword = (password: string) => ({password: password, loginFailed: false})

export default withStyles(styles)(LoginPage)
