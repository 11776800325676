import {Auth} from "../types/types";
import {RootState} from "./reducers/reducers";


const AUTH_STATE_KEY = "authState"


export const loadState = (): Partial<RootState> | undefined => {
	const authState = loadAuthState()
	if (!authState) {
		return undefined
	}

	return {
		auth: {
			email: authState.username,
			password: authState.password,
			persistCredentials: true
		}
	}
}

const loadAuthState = (): Auth | undefined => {
	try {
		const serializedState = localStorage.getItem(AUTH_STATE_KEY)
		if (serializedState === null) {
			return undefined
		} else {
			return JSON.parse(serializedState)
		}
	} catch (e) {
		return undefined
	}
}

export const saveAuthState = (auth: Auth, persistCredentials: boolean) => {
	try {
		let serializedState: string;
		if (persistCredentials) {
			serializedState = JSON.stringify(auth)
		} else {
			serializedState = ""
		}

		localStorage.setItem(AUTH_STATE_KEY, serializedState)
	} catch (e) {
		// ignore write error
	}
}