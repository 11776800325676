import * as React from "react"
import {connect} from "react-redux"
import {CisternDto} from "../../api/dto/CisternDto"
import {RootState} from "../../store/reducers/reducers"
import {getCisterns, isDrawerOpen} from "../../store/reducers/selectors"
import WaterManagerDrawer from "./WaterManagerDrawer";
import * as usersActions from "../../store/actions/users"
import * as uiActions from "../../store/actions/ui"
import {RouteComponentProps, withRouter} from "react-router";


interface StateProps {
	cisterns: CisternDto[],
	open: boolean,
}

interface DispatchProps {
	onLogout(): void

	setDrawerState(open: boolean): void
}

const WaterManagerDrawerConnected: React.FC<StateProps & DispatchProps & RouteComponentProps> = (props) => {
	const onShowChangePassword = () => {
		props.setDrawerState(false)
		props.history.push("/password")
	}

	return <WaterManagerDrawer onShowChangePassword={onShowChangePassword} {...props}/>
}

const mapStateToProps = (state: RootState): StateProps => ({
	cisterns: getCisterns(state),
	open: isDrawerOpen(state)
})

const dispatchProps = {
	setDrawerState: uiActions.setDrawerState,
	onLogout: usersActions.onLogout
}

export default connect<StateProps, DispatchProps>(mapStateToProps, dispatchProps)(withRouter(WaterManagerDrawerConnected))
