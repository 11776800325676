import {combineReducers} from "redux"
import {getType} from "typesafe-actions"
import {CisternDto} from "../../api/dto/CisternDto"
import {WaterManagerActions} from "../actions/actions"
import {logout, receiveUserData} from "../actions/users"


export interface CisternsState {
	readonly cisterns: CisternDto[]
}

const cisterns = (state = [], action: WaterManagerActions): CisternDto[] => {
	switch (action.type) {
		case getType(receiveUserData):
			return action.payload.user.cisterns

		case getType(logout):
			return []

		default:
			return state
	}
}

export const cisternsReducers = combineReducers<CisternsState, WaterManagerActions>({
	cisterns,
})


export const getCisterns = (state: CisternsState): CisternDto[] => state.cisterns.sort((a, b) => a.cisternName.localeCompare(b.cisternName))

export const getCisternById = (state: CisternsState, cisternId: number): CisternDto | undefined =>
	state.cisterns.find(cistern => cistern.id === cisternId)