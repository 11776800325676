import * as React from "react"
import {connect} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import App from "./App"
import * as usersActions from "./store/actions/users"
import {RootState} from "./store/reducers/reducers"
import {isLoggedIn} from "./store/reducers/selectors"

interface StateProps {
	loggedIn: boolean
}

interface DispatchProps {
	checkLogin(): void
}

const AppConnected: React.FC<StateProps & DispatchProps> = (props) => <App {...props}/>

const mapStateToProps = (state: RootState): StateProps => ({loggedIn: isLoggedIn(state)})

export default withRouter(connect<StateProps, DispatchProps, RouteComponentProps>(mapStateToProps, usersActions)(AppConnected))
