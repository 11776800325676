import {RecordType} from "./RecordType"

export class WaterLevelRecordDto {
	constructor(public readonly id: number,
				public readonly date: Date,
				public readonly waterDistance: number,
				public readonly  waterHeight: number,
				public readonly waterVolume: number,
				public readonly waterLevel: number,
				public readonly  batteryVoltage: number,
				public readonly batteryPercent: number,
				public readonly temperature: number,
				public readonly humidity: number) {
	}

	public static fromJson(recordObject: any): WaterLevelRecordDto {
		return new WaterLevelRecordDto(recordObject.id,
			new Date(recordObject.date),
			recordObject.water_distance,
			recordObject.water_height,
			recordObject.water_volume,
			recordObject.water_level,
			recordObject.battery_voltage,
			recordObject.battery_percent,
			recordObject.temperature,
			recordObject.humidity,
		)
	}

	public getValueForRecordType(recordType: RecordType): number {
		switch (recordType) {
			case RecordType.WATER_DISTANCE:
				return this.waterDistance
			case RecordType.WATER_HEIGHT:
				return this.waterHeight
			case RecordType.WATER_VOLUME:
				return this.waterVolume
			case RecordType.WATER_LEVEL_PERCENT:
				return this.waterLevel

			case RecordType.BATTERY_VOLTAGE:
				return this.batteryVoltage
			case RecordType.BATTERY_LEVEL_PERCENT:
				return this.batteryPercent

			case RecordType.TEMPERATURE:
				return this.temperature
			case RecordType.HUMIDITY:
				return this.humidity

			default:
				return 0
		}
	}
}
