import * as React from "react"
import {Typography} from "@material-ui/core"
import {connect} from "react-redux"
import {CisternDto} from "../../api/dto/CisternDto"
import {RootState} from "../../store/reducers/reducers"
import {getSelectedCistern, getSelectedViewPortSize} from "../../store/reducers/selectors"
import OverviewPage from "./OverviewPage"
import {ViewPortSize} from "../../types/ViewPortSize";


interface StateProps {
	selectedCistern?: CisternDto,
	selectedViewPortSize: ViewPortSize
}

const OverviewPageConnected: React.FC<StateProps> = (props) => {
	if (props.selectedCistern) {
		return <OverviewPage selectedCistern={props.selectedCistern} {...props}/>
	} else {
		return <div><Typography>Sie haben keinen Zugriff auf die gewählte Brunnestub.</Typography></div>
	}
}

const mapStateToProps = (state: RootState): StateProps => ({
	selectedCistern: getSelectedCistern(state),
	selectedViewPortSize: getSelectedViewPortSize(state)
})

export default connect<StateProps>(mapStateToProps)(OverviewPageConnected)
